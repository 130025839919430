import * as i0 from '@angular/core';
import { Directive, Input, Injectable, ContentChildren, ContentChild, Component, ChangeDetectionStrategy, Optional, NgModule } from '@angular/core';
import { NgIf, NgFor, NgTemplateOutlet } from '@angular/common';
import { Subject, combineLatest, distinctUntilChanged, map } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';
import * as i3 from '@angular/forms';

/* eslint-disable @angular-eslint/directive-selector,@angular-eslint/no-input-rename */
/**
 * Directive allowing to define the template for an error of a given type (using the `valError` input), using an ng-template.
 * It's used inside the body of the validation errors component, or inside the body of the default validation errors directive.
 * See the documentation of these two for example usages.
 */
const _c0 = (a0, a1) => ({
  $implicit: a0,
  error: a1
});
const _c1 = (a0, a1, a2) => ({
  $implicit: a0,
  type: a1,
  error: a2
});
function ValidationErrorsComponent_ng_container_0_ng_container_1_div_1_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function ValidationErrorsComponent_ng_container_0_ng_container_1_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtemplate(1, ValidationErrorsComponent_ng_container_0_ng_container_1_div_1_ng_container_1_Template, 1, 0, "ng-container", 2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const errorDirective_r1 = ctx.$implicit;
    const vm_r2 = i0.ɵɵnextContext(2).ngIf;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(ctx_r2.errorClasses);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", errorDirective_r1.templateRef)("ngTemplateOutletContext", i0.ɵɵpureFunction2(4, _c0, ctx_r2.label, vm_r2.control.errors[errorDirective_r1.type]));
  }
}
function ValidationErrorsComponent_ng_container_0_ng_container_1_div_2_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function ValidationErrorsComponent_ng_container_0_ng_container_1_div_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtemplate(1, ValidationErrorsComponent_ng_container_0_ng_container_1_div_2_ng_container_1_Template, 1, 0, "ng-container", 2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const error_r4 = ctx.$implicit;
    const vm_r2 = i0.ɵɵnextContext(2).ngIf;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(ctx_r2.errorClasses);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", vm_r2.errorsToDisplay.fallback.templateRef)("ngTemplateOutletContext", i0.ɵɵpureFunction3(4, _c1, ctx_r2.label, error_r4.type, error_r4.value));
  }
}
function ValidationErrorsComponent_ng_container_0_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, ValidationErrorsComponent_ng_container_0_ng_container_1_div_1_Template, 2, 7, "div", 1)(2, ValidationErrorsComponent_ng_container_0_ng_container_1_div_2_Template, 2, 8, "div", 1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const vm_r2 = i0.ɵɵnextContext().ngIf;
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", vm_r2.errorsToDisplay.errors);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", vm_r2.errorsToDisplay.fallbackErrors);
  }
}
function ValidationErrorsComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, ValidationErrorsComponent_ng_container_0_ng_container_1_Template, 3, 2, "ng-container", 0);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const vm_r2 = ctx.ngIf;
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", vm_r2.shouldDisplayErrors);
  }
}
class ValidationErrorDirective {
  constructor(templateRef) {
    this.templateRef = templateRef;
    /**
     * The type of the error that the content of the template must display.
     */
    this.type = '';
  }
  static ngTemplateContextGuard(directive, context) {
    return true;
  }
  static {
    this.ɵfac = function ValidationErrorDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ValidationErrorDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: ValidationErrorDirective,
      selectors: [["ng-template", "valError", ""]],
      inputs: {
        type: [0, "valError", "type"]
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ValidationErrorDirective, [{
    type: Directive,
    args: [{
      selector: 'ng-template[valError]',
      standalone: true
    }]
  }], () => [{
    type: i0.TemplateRef
  }], {
    type: [{
      type: Input,
      args: [{
        alias: 'valError',
        required: true
      }]
    }]
  });
})();

/* eslint-disable @angular-eslint/directive-selector,@angular-eslint/no-input-rename */
/**
 * Directive allowing to define a fallback template for an error of a type that is not handled by any validation error directive.
 * It's used inside the body of the validation errors component, or inside the body of the default validation errors directive.
 * See the documentation of these two for example usages.
 *
 * This is useful to handle forgotten errors instead of displaying no error at all, or to handle all or several error types in the same way,
 * for example by relying on the error key to choose an internationalized message.
 */
class ValidationFallbackDirective {
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
  static ngTemplateContextGuard(directive, context) {
    return true;
  }
  static {
    this.ɵfac = function ValidationFallbackDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ValidationFallbackDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: ValidationFallbackDirective,
      selectors: [["ng-template", "valFallback", ""]],
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ValidationFallbackDirective, [{
    type: Directive,
    args: [{
      selector: 'ng-template[valFallback]',
      standalone: true
    }]
  }], () => [{
    type: i0.TemplateRef
  }], null);
})();

/**
 * Service used by the default validation errors directive to store the default error template references. This
 * service is injected in the validation errors component which displays the appropriate templates and provides their context.
 */
class DefaultValidationErrors {
  constructor() {
    this.directives = [];
  }
  static {
    this.ɵfac = function DefaultValidationErrors_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DefaultValidationErrors)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: DefaultValidationErrors,
      factory: DefaultValidationErrors.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DefaultValidationErrors, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();

/* eslint-disable @angular-eslint/directive-selector,@angular-eslint/no-host-metadata-property */
/**
 * Directive allowing to register default templates for validation error messages. It's supposed to be used once,
 * typically in the root component. By using templates to do that, error messages can
 * - easily be i18ned
 * - easily use pipes
 * - easily use HTML
 * - easily be ordered
 *
 * Example usage:
 * ```
 *   <val-default-errors>
 *     <ng-template valError="required">This field is mandatory</ng-template>
 *     <ng-template valError="max" let-error="error">This field must be at most {{ error.max | number }}</ng-template>
 *   </val-default-errors>
 * ```
 *
 * Example usage where a label is used to make the messages less generic:
 * ```
 *   <val-default-errors>
 *     <ng-template valError="required" let-label>{{ label }} is mandatory</ng-template>
 *     <ng-template valError="max" let-error="error" let-label>{{ label }} must be at most {{ error.max | number }}</ng-template>
 *   </val-default-errors>
 * ```
 *
 * A fallback template can also be provided. This fallback template is used for all the errors that exist on the form control
 * but are not handled by any of the specific error templates:
 * ```
 *   <val-default-errors>
 *     <ng-template valError="required" let-label>{{ label }} is mandatory</ng-template>
 *     <ng-template valError="max" let-error="error" let-label>{{ label }} must be at most {{ error.max | number }}</ng-template>
 *     <ng-template valFallback let-label let-type="type" let-error="error">{{ label }} has an unhandled error of type {{ type }}: {{ error | json }}</ng-template>
 *   </val-default-errors>
 * ```
 * Using the fallback can also be used to handle all the errors the same way, for example by using the error type as an i18n key
 * to display the appropriate error message.
 *
 * This directive stores the default template references in a service, that is then injected in the validation errors components
 * to be reused.
 */
class DefaultValidationErrorsDirective {
  constructor(defaultValidationErrors) {
    this.defaultValidationErrors = defaultValidationErrors;
  }
  ngAfterContentInit() {
    this.defaultValidationErrors.directives = this.errorDirectives.toArray();
    this.defaultValidationErrors.fallback = this.fallbackDirective;
  }
  static {
    this.ɵfac = function DefaultValidationErrorsDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DefaultValidationErrorsDirective)(i0.ɵɵdirectiveInject(DefaultValidationErrors));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: DefaultValidationErrorsDirective,
      selectors: [["val-default-errors"]],
      contentQueries: function DefaultValidationErrorsDirective_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, ValidationFallbackDirective, 5);
          i0.ɵɵcontentQuery(dirIndex, ValidationErrorDirective, 4);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.fallbackDirective = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.errorDirectives = _t);
        }
      },
      hostAttrs: [2, "display", "none"],
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DefaultValidationErrorsDirective, [{
    type: Directive,
    args: [{
      selector: 'val-default-errors',
      host: {
        style: 'display: none'
      },
      standalone: true
    }]
  }], () => [{
    type: DefaultValidationErrors
  }], {
    errorDirectives: [{
      type: ContentChildren,
      args: [ValidationErrorDirective]
    }],
    fallbackDirective: [{
      type: ContentChild,
      args: [ValidationFallbackDirective]
    }]
  });
})();

/**
 * The display mode of the validation errors. For a given control, either all the validation errors
 * are displayed, or only the first one.
 */
var DisplayMode;
(function (DisplayMode) {
  DisplayMode[DisplayMode["ALL"] = 0] = "ALL";
  DisplayMode[DisplayMode["ONE"] = 1] = "ONE";
})(DisplayMode || (DisplayMode = {}));
/**
 * The configuration service used by the validation errors component to apply common rules for all
 * form controls.
 *
 * To change its default behavior, you can either inject this service in your root module or component and mutate it,
 * or define your own implementation and provide it.
 */
class ValdemortConfig {
  constructor() {
    /**
     * The display mode of the errors. The default value is ALL, meaning that all the errors existing on a control
     * (and which have an error template defined) are displayed.
     */
    this.displayMode = DisplayMode.ALL;
    /**
     * Specifies one or several CSS classes (separated by a white space) that are automatically added to the
     * validation errors element. This can be useful to reuse a standard CSS class of your CSS framework (like
     * .invalid-feedback in BootStrap), rather than styling the val-errors element itself.
     *
     * The default value is null (no class is added).
     */
    this.errorsClasses = null;
    /**
     * Specifies one or several CSS classes (separated by a white space) that are automatically added to the
     * each validation error message element. This can be useful to reuse a standard CSS class of your CSS framework
     * rather than styling the div element itself.
     *
     * The default value is null (no class is added).
     */
    this.errorClasses = null;
    /**
     * Specifies when error messages should be displayed. based on the state of the control itself (touched, dirty, etc.)
     * and on the state of the form directive containing it (if any). This function is only called if the control is invalid
     * in the first place: if it's valid, errors are never displayed.
     *
     * The default value of this function returns true if the control is touched, or if the form (if any) is submitted.
     */
    this.shouldDisplayErrors = (control, form) => control.touched || !!form && form.submitted;
    /**
     * Specifies if the library should throw an error when a control is not found.
     * For example, this can happen if a typo was made in the `controlName`.
     * If the check is enabled, then an error will be thrown in such a case.
     * Otherwise, the error is silently ignored.
     *
     * The default value of this function returns false, thus disabling the check.
     *
     * You can enable the check by giving it a function that returns true,
     * or you can enable it only in development for example with:
     * `config.shouldThrowOnMissingControl = () => !environment.production`
     */
    this.shouldThrowOnMissingControl = () => false;
  }
  static {
    this.ɵfac = function ValdemortConfig_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ValdemortConfig)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ValdemortConfig,
      factory: ValdemortConfig.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ValdemortConfig, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();

/* eslint-disable @angular-eslint/no-host-metadata-property */
const NO_ERRORS = {
  shouldDisplayErrors: false
};
const NO_VALIDATION_STATE = {
  control: null,
  errorsDisplayed: null,
  errors: null
};
function areValidationStatesEqual(previous, current) {
  return previous.control === current.control && previous.errorsDisplayed === current.errorsDisplayed && previous.errors === current.errors;
}
/**
 * Component allowing to display validation error messages associated to a given form control, form group or form array.
 * The control is provided using the `control` input of the component. If it's used inside an enclosing form group or
 * form array, it can instead be provided using the `controlName` input of the component.
 *
 * Example usage where the control itself is being passed as input:
 * ```
 *   <val-errors [control]="form.controls.birthDate">
 *     <ng-template valError="required">The birth date is mandatory</ng-template>
 *     <ng-template valError="max" let-error="error">The max value for the birth date is {{ error.max | number }}</ng-template>
 *   </val-errors>
 * ```
 *
 * Example usage where the control name is being passed as input:
 * ```
 *   <val-errors controlName="birthDate">
 *     <ng-template valError="required">The birth date is mandatory</ng-template>
 *     <ng-template valError="max" let-error="error">The max value for the birth date is {{ error.max | number }}</ng-template>
 *   </val-errors>
 * ```
 *
 * This component, if the control is invalid, displays its validation errors using the provided templates.
 * The templates, as shown in the above example, have access to the validation error itself.
 *
 * The label of the control can also be provided as input, and then used in the templates:
 * ```
 *   <val-errors controlName="birthDate" label="the birth date">
 *     <ng-template valError="required" let-label>{{ label }} is mandatory</ng-template>
 *     <ng-template valError="max" let-error="error" let-label>The max value for {{ label }} is {{ error.max | number }}</ng-template>
 *   </val-errors>
 * ```
 *
 * The component‘s behavior is configured globally by the Config service (see its documentation for more details). It can
 * - display the first error, or all the errors
 * - add CSS classes to its host `<val-errors>` element
 * - add CSS classes to each error message element being displayed
 * - choose when to display the errors (dirty, touched, touched and submitted, etc.)
 *
 * Global, default templates can be defined (and used by this component) using the default validation errors directive
 * (see its documentation for details). So, if the default error messages are defined and sufficient for a given control, all you
 * need is
 *
 * ```
 * <val-errors controlName="birthDate"></val-errors>
 * ```
 *
 * or, if the default templates expect a label:
 *
 * ```
 * <val-errors controlName="birthDate" label="the birth date"></val-errors>
 * ```
 *
 * If, however, you want to override one or several error messages by custom ones, you can do so by simply defining them inside the
 * component:
 *
 * ```
 * <val-errors controlName="birthDate" label="the birth date">
 *   <ng-template valError="max">You're too young, sorry</ng-template>
 * </val-errors>
 * ```
 *
 * A fallback template can also be provided. This fallback template is used for all the errors that exist on the form control
 * but are not handled by any of the specific error templates:
 * ```
 * <val-errors controlName="birthDate" label="the birth date">
 *   <ng-template valError="max">You're too young, sorry</ng-template>
 *   <ng-template valFallback let-label let-type="type" let-error="error">{{ label }} has an unhandled error of type {{ type }}: {{ error | json }}</ng-template>
 * </val-errors>
 * ```
 * Note that, the fallback template can also be defined in the default validation errors directive (see its documentation for details).
 * If a fallback template is defined inside `val-errors`, it overrides the default fallback.
 *
 * If an error is present on the control, but doesn't have any template, default template or fallback template defined for its type,
 * then it's not displayed. If the control is valid, or if none of the errors of the component has a matching template or default template,
 * then this component itself is hidden.
 */
class ValidationErrorsComponent {
  /**
   * @param config the Config service instance, defining the behavior of this component
   * @param defaultValidationErrors the service holding the default error templates, optionally
   * defined by using the default validation errors directive
   * @param controlContainer one of the 4 form group or form array directives that can "wrap" the control.
   * It's injected so that we can know if it exists and, if it does, if its form directive has been submitted or not:
   * the config service shouldDisplayErrors function can choose (and does by default) to use that information.
   */
  constructor(config, defaultValidationErrors, controlContainer) {
    this.config = config;
    this.defaultValidationErrors = defaultValidationErrors;
    this.controlContainer = controlContainer;
    /**
     * The FormControl, FormGroup or FormArray containing the validation errors.
     * If set, the controlName input is ignored
     */
    this.control = null;
    /**
     * The name (or the index, in case it's contained in a FormArray) of the FormControl, FormGroup or FormArray containing the validation
     * errors.
     * Ignored if the control input is set, and only usable if the control to validate is part of a control container
     */
    this.controlName = null;
    /**
     * The label of the field, exposed to templates so they can use it in the error message.
     */
    this.label = null;
    this.errorsClasses = this.config.errorsClasses || '';
    this.errorClasses = this.config.errorClasses || '';
    this.validationStateChanges = new Subject();
    this.contentInit = new Subject();
    this.vm = toSignal(combineLatest([this.validationStateChanges.pipe(distinctUntilChanged(areValidationStatesEqual)), this.contentInit]).pipe(map(([validationState]) => {
      const ctrl = validationState.control;
      if (this.shouldDisplayErrors(ctrl)) {
        const errorsToDisplay = this.findErrorsToDisplay(ctrl);
        return {
          shouldDisplayErrors: true,
          control: ctrl,
          errorsToDisplay
        };
      } else {
        return NO_ERRORS;
      }
    })), {
      initialValue: NO_ERRORS
    });
  }
  ngAfterContentInit() {
    this.contentInit.next();
  }
  ngDoCheck() {
    const ctrl = this.findActualControl();
    if (ctrl) {
      const formDirective = this.controlContainer?.formDirective;
      const errorsDisplayed = this.config.shouldDisplayErrors(ctrl, formDirective);
      this.validationStateChanges.next({
        control: ctrl,
        errorsDisplayed,
        errors: ctrl.errors
      });
    } else {
      this.validationStateChanges.next(NO_VALIDATION_STATE);
    }
  }
  shouldDisplayErrors(ctrl) {
    if (!ctrl || !ctrl.invalid || !this.hasDisplayableError(ctrl)) {
      return false;
    }
    const form = this.controlContainer && this.controlContainer.formDirective;
    return this.config.shouldDisplayErrors(ctrl, form);
  }
  findErrorsToDisplay(ctrl) {
    const mergedDirectives = [];
    const fallbackErrors = [];
    const alreadyMetTypes = new Set();
    const shouldContinue = () => this.config.displayMode === DisplayMode.ALL || mergedDirectives.length === 0 && fallbackErrors.length === 0;
    for (let i = 0; i < this.defaultValidationErrors.directives.length && shouldContinue(); i++) {
      const defaultDirective = this.defaultValidationErrors.directives[i];
      if (ctrl.hasError(defaultDirective.type)) {
        const customDirectiveOfSameType = this.errorDirectives.find(dir => dir.type === defaultDirective.type);
        mergedDirectives.push(customDirectiveOfSameType || defaultDirective);
      }
      alreadyMetTypes.add(defaultDirective.type);
    }
    if (shouldContinue()) {
      const customDirectives = this.errorDirectives.toArray();
      for (let i = 0; i < customDirectives.length && shouldContinue(); i++) {
        const customDirective = customDirectives[i];
        if (ctrl.hasError(customDirective.type) && !alreadyMetTypes.has(customDirective.type)) {
          mergedDirectives.push(customDirective);
        }
        alreadyMetTypes.add(customDirective.type);
      }
    }
    if (shouldContinue() && (this.fallbackDirective || this.defaultValidationErrors.fallback)) {
      const allErrors = Object.entries(ctrl.errors ?? []);
      for (let i = 0; i < allErrors.length && shouldContinue(); i++) {
        const [type, value] = allErrors[i];
        if (!alreadyMetTypes.has(type)) {
          fallbackErrors.push({
            type,
            value
          });
        }
      }
    }
    return {
      errors: mergedDirectives,
      fallback: this.fallbackDirective ?? this.defaultValidationErrors.fallback,
      fallbackErrors
    };
  }
  findActualControl() {
    if (this.control) {
      return this.control;
    } else if ((this.controlName || this.controlName === 0) && this.controlContainer.control?.controls) {
      const control = this.controlContainer.control.controls[this.controlName];
      if (this.config.shouldThrowOnMissingControl()) {
        // if the control is null, then there are two cases:
        // - we are in a template driven form, and the controls might not be initialized yet
        // - there was an error in the control name. If so, let's throw an error to help developers
        // to avoid false positive in template driven forms, we check if the controls are initialized
        // by checking if the `controls` object or array has any element
        if (!control && Object.keys(this.controlContainer.control?.controls).length > 0) {
          throw new Error(`ngx-valdemort: no control found for controlName: '${this.controlName}'.`);
        }
      }
      return control;
    }
    return null;
  }
  hasDisplayableError(ctrl) {
    return ctrl.errors && (this.fallbackDirective || this.defaultValidationErrors.fallback || Object.keys(ctrl.errors).some(type => this.defaultValidationErrors.directives.some(dir => dir.type === type) || this.errorDirectives.some(dir => dir.type === type)));
  }
  static {
    this.ɵfac = function ValidationErrorsComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ValidationErrorsComponent)(i0.ɵɵdirectiveInject(ValdemortConfig), i0.ɵɵdirectiveInject(DefaultValidationErrors), i0.ɵɵdirectiveInject(i3.ControlContainer, 8));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ValidationErrorsComponent,
      selectors: [["val-errors"]],
      contentQueries: function ValidationErrorsComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, ValidationFallbackDirective, 5);
          i0.ɵɵcontentQuery(dirIndex, ValidationErrorDirective, 4);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.fallbackDirective = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.errorDirectives = _t);
        }
      },
      hostVars: 4,
      hostBindings: function ValidationErrorsComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.errorsClasses);
          i0.ɵɵstyleProp("display", ctx.vm().shouldDisplayErrors ? "" : "none");
        }
      },
      inputs: {
        control: "control",
        controlName: "controlName",
        label: "label"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 1,
      vars: 1,
      consts: [[4, "ngIf"], [3, "class", 4, "ngFor", "ngForOf"], [4, "ngTemplateOutlet", "ngTemplateOutletContext"]],
      template: function ValidationErrorsComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, ValidationErrorsComponent_ng_container_0_Template, 2, 1, "ng-container", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", ctx.vm());
        }
      },
      dependencies: [NgIf, NgFor, NgTemplateOutlet],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ValidationErrorsComponent, [{
    type: Component,
    args: [{
      selector: 'val-errors',
      host: {
        '[class]': 'errorsClasses',
        '[style.display]': `vm().shouldDisplayErrors ? '' : 'none'`
      },
      standalone: true,
      imports: [NgIf, NgFor, NgTemplateOutlet],
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<ng-container *ngIf=\"vm() as vm\">\n  <ng-container *ngIf=\"vm.shouldDisplayErrors\">\n    <div [class]=\"errorClasses\" *ngFor=\"let errorDirective of vm.errorsToDisplay.errors\">\n      <ng-container\n        *ngTemplateOutlet=\"errorDirective!.templateRef; context: {\n        $implicit: label,\n        error: vm.control.errors![errorDirective.type]\n      }\"\n      ></ng-container>\n    </div>\n    <div [class]=\"errorClasses\" *ngFor=\"let error of vm.errorsToDisplay.fallbackErrors\">\n      <ng-container\n        *ngTemplateOutlet=\"vm.errorsToDisplay.fallback!.templateRef; context: {\n        $implicit: label,\n        type: error.type,\n        error: error.value\n      }\"\n      ></ng-container>\n    </div>\n  </ng-container>\n</ng-container>\n"
    }]
  }], () => [{
    type: ValdemortConfig
  }, {
    type: DefaultValidationErrors
  }, {
    type: i3.ControlContainer,
    decorators: [{
      type: Optional
    }]
  }], {
    control: [{
      type: Input
    }],
    controlName: [{
      type: Input
    }],
    label: [{
      type: Input
    }],
    errorDirectives: [{
      type: ContentChildren,
      args: [ValidationErrorDirective]
    }],
    fallbackDirective: [{
      type: ContentChild,
      args: [ValidationFallbackDirective]
    }]
  });
})();
class ValdemortModule {
  static {
    this.ɵfac = function ValdemortModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ValdemortModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: ValdemortModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ValdemortModule, [{
    type: NgModule,
    args: [{
      imports: [ValidationErrorsComponent, ValidationErrorDirective, ValidationFallbackDirective, DefaultValidationErrorsDirective],
      exports: [ValidationErrorsComponent, ValidationErrorDirective, ValidationFallbackDirective, DefaultValidationErrorsDirective]
    }]
  }], null, null);
})();

/* eslint-disable */
/*
 * Public API Surface of ngx-validation-errors
 */

/**
 * Generated bundle index. Do not edit.
 */

export { DefaultValidationErrorsDirective, DisplayMode, ValdemortConfig, ValdemortModule, ValidationErrorDirective, ValidationErrorsComponent, ValidationFallbackDirective };
